export default [
  {
    title: 'Contract Terms',
    description: 'Enter the main terms of the contract',
    forms: [
      {
        id: 'startDate',
        label: 'Start Date',
        type: 'datePicker',
        placeholder: 'Enter a start date'
      },
      {
        id: 'endDate',
        label: 'End Date',
        type: 'datePicker',
        placeholder: 'Enter an end date'
      },
      {
        id: 'terminationDate',
        label: 'Termination Date',
        type: 'datePicker',
        placeholder: 'Enter a termination date'
      },
      {
        id: 'paymentTerms',
        label: 'Payment Terms (days)',
        type: 'number',
        placeholder: 'Enter days'
      }
    ]
  },
  {
    title: 'Tariff Details',
    description: 'Details relating the the tariff',
    forms: [
      {
        id: 'tariffName',
        label: 'Tariff Name',
        type: 'text',
        placeholder: 'Enter a tariff name'
      },
      {
        id: 'contractConsumption',
        label: 'Contract Consumption',
        type: 'number',
        placeholder: 'Enter a contract consumption'
      },
      {
        id: 'volumeTolerance',
        label: 'Annual Volume Tolerance',
        type: 'number',
        placeholder: 'Enter a volume tolerance'
      },
      {
        id: 'renewablePercentage',
        label: 'Renewable Percentage',
        type: 'number',
        placeholder: 'Enter a renewable percentage'
      },
      {
        id: 'chargeableCclPercentage',
        label: 'Chargeable CCL Percentage',
        type: 'number',
        placeholder: 'Enter a chargeable CCL percentage'
      }
    ]
  }
];
