var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-accounts-contracts-page"
    }
  }, [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-file-contract",
      "action-position": "right"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function fn() {
        return [_vm._v("View and upload any contracts related to this account.")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
          staticClass: "btn btn-primary",
          attrs: {
            "data-cy": "add-new-btn"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onClickCreate($event);
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-fw fa-plus"
        }), _vm._v(" NEW ")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [[_vm._v("Contracts")]], 2), Object.keys(_vm.validationErrors).length > 0 ? _c('div', {
    staticClass: "alert alert-danger",
    attrs: {
      "role": "alert"
    }
  }, [_c('h3', {
    staticClass: "alert-heading font-size-h4 my-2"
  }, [_vm._v("Error")]), _vm._l(Object.values(_vm.validationErrors), function (error, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(error))]);
  })], 2) : _vm._e(), _vm.showAdd ? _c('div', {
    staticClass: "block",
    attrs: {
      "data-cy": "contracts-add"
    }
  }, [_c('div', {
    staticClass: "block-content bg-body-light"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('h4', [_vm._v("Create/Edit contract")]), _c('button', {
    staticClass: "btn btn-link text-danger fa-lg",
    attrs: {
      "data-cy": "contract-close"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.handleDropdownClose($event);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-times"
  })])]), _c('p', [_vm._v(" Please fill out the form below with details of this contract. These details will be used when validating invoices, so please ensure they are 100% correct. ")]), _c('div', [_vm._m(0), _c('div', {
    staticClass: "row push"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('FormGroup', {
    staticClass: "col-md-6",
    attrs: {
      "id": "startDate",
      "type": "datePicker",
      "label": "Start Date",
      "placeholder": "",
      "error": _vm.validationErrors.startDate
    },
    model: {
      value: _vm.form.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "startDate", $$v);
      },
      expression: "form.startDate"
    }
  }), _c('FormGroup', {
    staticClass: "col-md-6",
    attrs: {
      "id": "endDate",
      "type": "datePicker",
      "label": "End Date",
      "placeholder": "",
      "error": _vm.validationErrors.endDate
    },
    model: {
      value: _vm.form.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "endDate", $$v);
      },
      expression: "form.endDate"
    }
  })], 1), _c('FormGroup', {
    attrs: {
      "id": "terminationDate",
      "type": "datePicker",
      "label": "Termination Date",
      "placeholder": ""
    },
    model: {
      value: _vm.form.terminationDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "terminationDate", $$v);
      },
      expression: "form.terminationDate"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "paymentTerms",
      "type": "number",
      "label": "Payment Terms (days)",
      "placeholder": ""
    },
    model: {
      value: _vm.form.paymentTerms,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "paymentTerms", $$v);
      },
      expression: "form.paymentTerms"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "status",
      "type": "select",
      "label": "Status",
      "options": [{
        label: 'Active',
        value: 'active'
      }, {
        label: 'Inactive',
        value: 'inactive'
      }]
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  })], 1)])]), _c('div', [_vm._m(2), _c('div', {
    staticClass: "row push"
  }, [_vm._m(3), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "tariffName",
      "type": "text",
      "label": "Tariff Name",
      "placeholder": ""
    },
    model: {
      value: _vm.form.tariffName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tariffName", $$v);
      },
      expression: "form.tariffName"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "contractConsumption",
      "type": "number",
      "label": "Contract Consumption",
      "placeholder": ""
    },
    model: {
      value: _vm.form.contractConsumption,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contractConsumption", $$v);
      },
      expression: "form.contractConsumption"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "volumeTolerance",
      "type": "slider",
      "min": -100,
      "max": 100,
      "slider-type": "double",
      "label": "Annual Volume Tolerance",
      "placeholder": ""
    },
    model: {
      value: _vm.form.volumeTolerance,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "volumeTolerance", $$v);
      },
      expression: "form.volumeTolerance"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "renewablePercentage",
      "type": "slider",
      "label": "Renewable Percentage",
      "placeholder": ""
    },
    model: {
      value: _vm.form.renewablePercentage,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "renewablePercentage", $$v);
      },
      expression: "form.renewablePercentage"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "commissionRates",
      "type": "number",
      "label": "Commission Rates",
      "placeholder": ""
    },
    model: {
      value: _vm.form.commissionRates,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "commissionRates", $$v);
      },
      expression: "form.commissionRates"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "supplierId",
      "options": _vm.sortedSuppliers.map(function (s) {
        return {
          label: s.name,
          value: s._id
        };
      }),
      "label": "Supplier Name",
      "type": "select2",
      "error": _vm.validationErrors.supplierId
    },
    model: {
      value: _vm.form.supplierId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "supplierId", $$v);
      },
      expression: "form.supplierId"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "chargeableCclPercentage",
      "type": "number",
      "label": "Chargeable CCL Percentage",
      "placeholder": ""
    },
    model: {
      value: _vm.form.chargeableCclPercentage,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "chargeableCclPercentage", $$v);
      },
      expression: "form.chargeableCclPercentage"
    }
  })], 1)])]), _c('div', [_vm._m(4), _c('div', {
    staticClass: "row push"
  }, [_vm._m(5), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_vm._m(6), _c('input', {
    attrs: {
      "id": "contract-file",
      "type": "file",
      "name": "contract-file",
      "accept": ".pdf",
      "enctype": "multipart/form-data"
    },
    on: {
      "change": _vm.onFileSelect
    }
  })])])])]), _c('div', [_vm._m(7), _c('div', {
    staticClass: "row push"
  }, [_vm._m(8), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_vm._l(_vm.form.rates, function (rate, index) {
    return _c('div', {
      key: index,
      staticClass: "row align-items-center"
    }, [_c('div', {
      staticClass: "col-md-5"
    }, [_c('FormGroup', {
      attrs: {
        "id": "newContractType",
        "label": "Name",
        "placeholder": "Enter a type"
      },
      model: {
        value: rate.type,
        callback: function callback($$v) {
          _vm.$set(rate, "type", $$v);
        },
        expression: "rate.type"
      }
    })], 1), _c('div', {
      staticClass: "col-md-5"
    }, [_c('FormGroup', {
      attrs: {
        "id": "newContractValue",
        "label": "Value",
        "placeholder": "Enter a value"
      },
      model: {
        value: rate.value,
        callback: function callback($$v) {
          _vm.$set(rate, "value", $$v);
        },
        expression: "rate.value"
      }
    })], 1), _c('div', {
      staticClass: "col-md-2"
    }, [_c('button', {
      staticClass: "btn btn-alt-danger",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickRemoveRate(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-minus"
    })])])]);
  }), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "newContractType",
      "label": "Name",
      "placeholder": "Enter a type"
    },
    model: {
      value: _vm.newRate.type,
      callback: function callback($$v) {
        _vm.$set(_vm.newRate, "type", $$v);
      },
      expression: "newRate.type"
    }
  })], 1), _c('div', {
    staticClass: "col-md-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "newContractValue",
      "label": "Value",
      "placeholder": "Enter a value"
    },
    model: {
      value: _vm.newRate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.newRate, "value", $$v);
      },
      expression: "newRate.value"
    }
  })], 1), _c('div', {
    staticClass: "col-md-2"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickAddRate($event);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  })])])])], 2)])]), _c('div', [_vm._m(9), _c('div', {
    staticClass: "row push"
  }, [_vm._m(10), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('table', {
    staticClass: "table table-borderless"
  }, [_vm._m(11), _c('tbody', [_vm._l(_vm.form.rateTypeMapping, function (contractRateName, invoiceRateName) {
    return _c('tr', {
      key: invoiceRateName
    }, [_c('td', [_vm._v(" " + _vm._s(invoiceRateName) + " ")]), _vm._m(12, true), _c('td', [_vm._v(" " + _vm._s(contractRateName) + " ")]), _c('td', [_c('button', {
      staticClass: "btn btn-alt-danger",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickRemoveRateMapping(invoiceRateName);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-minus"
    })])])]);
  }), _c('tr', [_c('td', [_c('FormGroup', {
    attrs: {
      "id": "newRateMappingContract",
      "label": "",
      "placeholder": ""
    },
    model: {
      value: _vm.newRateMapping.invoice,
      callback: function callback($$v) {
        _vm.$set(_vm.newRateMapping, "invoice", $$v);
      },
      expression: "newRateMapping.invoice"
    }
  })], 1), _vm._m(13), _c('td', [_c('FormGroup', {
    attrs: {
      "id": "newRateMappingInvoice",
      "label": "",
      "type": "select",
      "options": _vm.form.rates.map(function (rate) {
        return {
          label: rate.type,
          value: rate.type
        };
      }),
      "placeholder": ""
    },
    model: {
      value: _vm.newRateMapping.contract,
      callback: function callback($$v) {
        _vm.$set(_vm.newRateMapping, "contract", $$v);
      },
      expression: "newRateMapping.contract"
    }
  })], 1), _c('td', [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickAddRateMapping($event);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  })])])])], 2)])])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-block",
    on: {
      "click": _vm.onClickSubmit
    }
  }, [_vm._v("Submit")])])])]) : _vm._e(), _vm.loadingAction.get ? _c('div', {
    staticClass: "d-flex justify-content-center mb-5"
  }, [_vm._m(14)]) : _vm.selectedAccount.contracts.length > 0 ? _c('div', {
    attrs: {
      "id": "accordion",
      "role": "tablist"
    }
  }, _vm._l(_vm.selectedAccount.contracts, function (contract) {
    return _c('div', {
      key: contract._id,
      staticClass: "block block-rounded mb-4"
    }, [_c('div', {
      staticClass: "block-header block-header-default",
      attrs: {
        "id": "accordion_h".concat(contract._id),
        "role": "tab"
      }
    }, [_c('a', {
      staticClass: "font-w600",
      attrs: {
        "data-toggle": "collapse",
        "data-parent": "#accordion",
        "href": "#accordion_q".concat(contract._id)
      }
    }, [_vm._v(" " + _vm._s(_vm._f("date")(contract.startDate)) + " - " + _vm._s(_vm._f("date")(contract.endDate)) + " "), contract.status === 'active' ? _c('span', {
      staticClass: "badge badge-success ml-1"
    }, [_vm._v("Active")]) : _vm._e()]), _c('span', [contract.status !== 'active' ? _c('button', {
      staticClass: "btn btn-link font-w600 text-success",
      attrs: {
        "disabled": _vm.loadingAction.updateContract
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickActive(contract);
          }($event);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-check-circle"
    }), _vm._v(" Set Active ")]) : _vm._e(), contract.status === 'active' ? _c('button', {
      staticClass: "btn btn-link font-w600 text-muted",
      attrs: {
        "disabled": _vm.loadingAction.updateContract
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickActive(contract);
          }($event);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-times-circle"
    }), _vm._v(" Set Inactive ")]) : _vm._e(), contract.s3Key ? _c('button', {
      staticClass: "btn btn-link font-w600",
      attrs: {
        "disabled": _vm.loadingAction.downloadContract
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickDownload(contract);
          }($event);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-file-pdf"
    }), _vm._v(" " + _vm._s(_vm.loadingAction.downloadContract ? 'Downloading...' : 'PDF') + " ")]) : _c('button', {
      staticClass: "btn btn-link font-w600",
      attrs: {
        "title": "This contract has no PDF",
        "disabled": ""
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-file-pdf"
    }), _vm._v(" PDF")]), _vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
      staticClass: "btn btn-link font-w600 text-info",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickEdit(contract);
          }($event);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-pencil"
    }), _vm._v(" Edit ")]) : _vm._e(), _vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
      staticClass: "btn btn-link font-w600 text-danger",
      attrs: {
        "disabled": _vm.loadingAction.deleteContract
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickDelete(contract);
          }($event);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-trash"
    }), _vm._v(" " + _vm._s(_vm.loadingAction.deleteContract === contract._id ? 'Deleting...' : 'Delete') + " ")]) : _vm._e()])]), _c('div', {
      staticClass: "collapse py-3",
      attrs: {
        "id": "accordion_q".concat(contract._id),
        "role": "tabpanel",
        "aria-labelledby": "accordion_h2",
        "data-parent": "#accordion"
      }
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-xl-6"
    }, [_c('SectionTitle', [_vm._v("Contract Details")]), _c('PropertyListItem', {
      staticClass: "d-flex align-items-center mb-2",
      attrs: {
        "title": "Tariff Name"
      }
    }, [_vm._v(_vm._s(contract.tariffName))]), _c('PropertyListItem', {
      staticClass: "d-flex align-items-center mb-2",
      attrs: {
        "title": "Termination Date"
      }
    }, [_vm._v(_vm._s(_vm._f("date")(contract.terminationDate)))]), _c('PropertyListItem', {
      staticClass: "d-flex align-items-center mb-2",
      attrs: {
        "title": "Annual Contract Consumption"
      }
    }, [_vm._v(_vm._s(contract.contractConsumption))]), _c('PropertyListItem', {
      staticClass: "d-flex align-items-center mb-2",
      attrs: {
        "title": "Volume Tolerance"
      }
    }, [_vm._v(_vm._s(contract.volumeTolerance))]), _c('PropertyListItem', {
      staticClass: "d-flex align-items-center mb-2",
      attrs: {
        "title": "Renewable %"
      }
    }, [_vm._v(_vm._s(contract.renewablePercentage))]), _c('PropertyListItem', {
      staticClass: "d-flex align-items-center mb-2",
      attrs: {
        "title": "Commission Rates"
      }
    }, [_vm._v(_vm._s(contract.commissionRates))]), _c('PropertyListItem', {
      staticClass: "d-flex align-items-center mb-2",
      attrs: {
        "title": "Chargeable CCL %"
      }
    }, [_vm._v(_vm._s(contract.chargeableCclPercentage))]), contract.supplierId ? _c('PropertyListItem', {
      staticClass: "d-flex align-items-center mb-2",
      attrs: {
        "title": "Supplier"
      }
    }, [_vm._v(_vm._s(_vm.handleSupplierName(contract.supplierId)))]) : _vm._e()], 1), _c('div', {
      staticClass: "col-xl-6"
    }, [_c('SectionTitle', [_vm._v("Rates")]), _vm._l(contract.rates, function (rate) {
      return _c('PropertyListItem', {
        key: rate.type,
        staticClass: "d-flex align-items-center mb-2",
        attrs: {
          "title": rate.type
        }
      }, [_vm._v(_vm._s(rate.value))]);
    })], 2)])])]);
  }), 0) : _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No contracts uploaded.")])]), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.remove,
      "title": "Delete Message",
      "text": "Please confirm you would like to remove this contract."
    },
    on: {
      "close": function close() {
        _vm.modals.remove = false;
      },
      "submit": _vm.onDelete
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-info text-muted mr-1"
  }), _vm._v("Contract Terms")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Enter the main terms of the contract")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-file-dollar text-muted mr-1"
  }), _vm._v("Tariff Details")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Details relating the the tariff")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-info text-muted mr-1"
  }), _vm._v("Contract File")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Upload a PDF of your contract")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "contract-file"
    }
  }, [_c('i', {
    staticClass: "fa fa-file-contract fa-fw"
  }), _vm._v(" Contract File")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-info text-muted mr-1"
  }), _vm._v("Contract Rates")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Add any rates found on the contract using the form.")]), _c('p', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v("Name")]), _c('br'), _vm._v("Enter the rate name as shown on the contract.")]), _c('p', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v("Value")]), _c('br'), _vm._v("The unit rate that is shown on the contract. This will be used for validation on invoices. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-info text-muted mr-1"
  }), _vm._v("Invoice Rate Mappings")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v(" When Captur processes an invoice, if it sees the "), _c('strong', [_vm._v("On Invoice")]), _vm._v(" value on an invoice, it will automatically map this to the "), _c('strong', [_vm._v("On Contract")]), _vm._v(" rate above. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "width": "35%"
    }
  }, [_vm._v("On Invoice")]), _c('th', {
    staticStyle: {
      "width": "15%"
    }
  }), _c('th', {
    staticStyle: {
      "width": "35%"
    }
  }, [_vm._v("On Contract")]), _c('th', {
    staticStyle: {
      "width": "15%"
    }
  })])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('i', {
    staticClass: "fa fa-fw fa-arrow-right"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('i', {
    staticClass: "fa fa-fw fa-arrow-right"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "spinner-border spinner-lg text-secondary mb-4",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading contracts...")])]), _c('div', {
    staticClass: "font-w600"
  }, [_vm._v("Loading contracts...")])]);
}]

export { render, staticRenderFns }